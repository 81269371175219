<template>
  <b-card title="Posts">
    <b-tabs>
    <b-tab active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Post</span>
        </template>

      </b-tab>
     
      <b-tab @click="$router.push('/all-articales')" >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>All Posts</span>
        </template>
        
<!-- <AllArticales></AllArticales> -->
      </b-tab>
      
     
    </b-tabs>
<div v-if="$route.path === '/add-article'">
      <AddArticale />
    </div>
    <div v-else>
      <router-view />
    </div>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'

import AllArticales from './AllArticales.vue';
import AddArticale from './AddArticale.vue';


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,

    AllArticales,
    AddArticale
},
  data() {
    return {
   
    }
  },
}
</script>
